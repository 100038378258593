// Core
@import "../../styles/core/_breakpoints";
@import "../../styles/core/_colors";
@import "../../styles/core/_variables.app";
@import "../../styles/core/_mixins";
@import "../../styles/core/_animations";
// DateTime
.wrapper {
    white-space: nowrap;
}

.fromNow {
}

.expired {
    color: @danger-color;
}
