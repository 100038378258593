// TODO: Refactor styles -> Split antd modifications to antd/*
.option-description {
    .flexCenter;
    justify-content: space-between;
    font-size: 0.8rem;

    div {
        .flexCenter;
        white-space: nowrap;
        gap: 7px;
    }

    dt {
        color: rgba(@black-color, 0.5);
    }
}

.ant-input-textarea-show-count::after {
    font-size: 11px;
    font-weight: 600;
    color: rgba(@black-color, 0.5);
}

// Grid cards (tmp => move to mixins)
.grid-cards {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));

    &.grid-large {
        grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    }

    &.grid-normal {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }

    &.grid-small {
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }

    &.grid-xs {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
}

.flex-grid {
    display: flex;
    gap: 20px;
    grid-gap: 20px;
}

.spin-fast {
    animation: fa-spin 0.3s linear infinite;
}

.ant-upload.ant-upload-drag {
    background: @background-alt;
}

.responsive-select-option {
    .ant-select-item-option-content {
        white-space: normal;
        word-break: break-word;
    }
}

.ant-form-item-control-input {
    .ant-select-item-option-content {
        white-space: nowrap;
        word-break: normal;
    }
}

.fa-spinner-third {
    .spin-fast;
}

// Compact modal
.ant-modal {
    .ant-modal-close-x {
        .size(46px);
        line-height: 46px;
    }

    .ant-modal-header {
        padding: 12px 15px;
    }
}

.muted-text {
    color: @muted-color;
}

.sales-pipeline-pending-table {
  .ant-table-cell[rowspan='2']{
    vertical-align: top;
  }
}
